import React from "react";
import AcquistaReportLayout from "../../components/acquista-report-layout";
import AcquistaThanks from "../../components/acquista-thanks";

const GrazieAcquistoBonificoPage = () => {
  return (
    <AcquistaReportLayout>
      <AcquistaThanks>
        <h1 className="fw-bold">Grazie per aver acquistato il report IIC</h1>
        <p>
          Riceverai una comunicazione all'indirizzo email che hai indicato nella sezione dei dati
          per la fatturazione con tutte le informazioni necessarie per effettuare il pagamento con
          bonifico.
        </p>
        <p>Una volta accreditato il bonifico riceverai il report e la relativa fattura.</p>
      </AcquistaThanks>
    </AcquistaReportLayout>
  );
};

export default GrazieAcquistoBonificoPage;
